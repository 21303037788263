<template>
  <div v-if="isLoading" style="display: flex; height: 100vh; align-items: center;">
    <v-progress-circular
      :size="70"
      :width="5"
      color="accent"
      indeterminate
      style="width: 100%; margin: 0 auto"
    ></v-progress-circular>
  </div>
  <div v-else>
    <v-card>
      <v-card-title>Rezultate</v-card-title>
      <v-divider></v-divider>
      <v-card-text
        ><v-tabs
          @change="changePoolType"
          grow
          background-color="white"
          show-arrows
        >
          <v-tabs-slider color="secondary"></v-tabs-slider>

          <v-tab v-for="poolType in poolTypes" :key="poolType.value">
            {{ poolType.text }}
          </v-tab>
        </v-tabs>
        <v-tabs
          @change="changeStyle"
          grow
          background-color="white"
          show-arrows
          slider-size="3"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab v-for="style in styles" :key="style.value">
            {{ style.text }}
          </v-tab>
        </v-tabs>
        <v-tabs
          @change="changeDistance"
          grow
          background-color="white"
          show-arrows
        >
          <v-tabs-slider color="accent"></v-tabs-slider>

          <v-tab v-for="distance in distances" :key="distance">
            {{ distance }}m
          </v-tab>
        </v-tabs>
        <v-row class="mt-2">
          <v-col cols="12" sm="12" md="12" lg="7"
            ><v-data-table
              :loading="isLoadingResults"
              :headers="resultsHeaders"
              :items="results"
              :expanded.sync="expanded"
              item-key="resultId"
              show-expand
              hide-default-footer
              single-expand
              no-data-text="Nu s-au gasit rezultate"
              loading-text="Se incarca rezultatele ..."
            >
              <template
                v-slot:item.data-table-expand="{ item, isExpanded, expand }"
              >
                <v-icon
                  v-if="item.splits.length > 0"
                  :class="[
                    'v-data-table__expand-icon',
                    { 'v-data-table__expand-icon--active': isExpanded },
                  ]"
                  @click.stop="expand(!isExpanded)"
                  >$expand</v-icon
                >
              </template>
              <template v-slot:item.date="{ item }">
                {{ $moment(item.date).format("DD-MM-YYYY HH:mm") }}
              </template>
              <template v-slot:item.miliseconds="{ item }">
                <b>{{ msToTime(item.miliseconds) }}</b>
              </template>
              <template v-slot:item.since="{ item }">
                {{ format(item.date, "ro") }}
              </template>
              <template v-slot:expanded-item="{ item }">
                <td colspan="4" style="width: 100vh">
                  <v-data-table
                    class="my-1"
                    hide-default-footer
                    :headers="[
                      {
                        text: 'Distanta',
                        value: 'distance',
                        sortable: false,
                      },
                      {
                        text: 'Timp intermediar',
                        value: 'time',
                        sortable: false,
                      },
                      {
                        text: 'Timp per split',
                        value: 'timeSplit',
                        sortable: false,
                      },
                      {
                        text: 'Variatie',
                        value: 'variation',
                        sortable: false,
                      },
                    ]"
                    :items="item.splits"
                  >
                    <template v-slot:item.distance="{ item }">
                      <v-chip color="primary" dark outlined>
                        {{ item.distance }}m
                      </v-chip>
                    </template>
                    <template v-slot:item.variation="{ item }">
                      <v-chip
                        v-if="item.variation !== ' '"
                        :color="
                          item.variation.startsWith('-')
                            ? 'green darken-3'
                            : 'red darken-3'
                        "
                        outlined
                        dark
                      >
                        <v-icon left>
                          {{
                            item.variation.startsWith("-")
                              ? "mdi-thumb-up-outline"
                              : "mdi-thumb-down-outline"
                          }}
                        </v-icon>
                        {{ item.variation }}
                      </v-chip>
                    </template>
                    <template v-slot:item.timeSplit="{ item }">
                      <v-chip
                        outlined
                        color="grey darken-3"
                        v-if="!item.isBestLap"
                      >
                        <v-icon left> mdi-timer-outline </v-icon>
                        {{ item.timeSplit }}
                      </v-chip>
                      <v-chip outlined color="yellow darken-4" v-else light>
                        <v-icon left> mdi-trophy </v-icon>
                        {{ item.timeSplit }}
                      </v-chip>
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="5"
            ><apexchart
              type="line"
              height="350"
              :options="chartOptions"
              :series="series"
            ></apexchart
          ></v-col> </v-row
      ></v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import { format } from "timeago.js";
import { mapState } from "vuex";
export default {
  name: "Results",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      format,
      isLoading: true,
      isLoadingResults: false,
      expanded: [true],
      styles: [
        { text: "Crawl", value: 1 },
        { text: "Bras", value: 2 },
        { text: "Spate", value: 3 },
        { text: "Fluture", value: 4 },
      ],
      poolTypes: [
        { text: "Bazin 50m", value: 2 },
        { text: "Bazin 25m", value: 1 },
      ],
      distances: [50, 100, 200, 400, 800, 1500],
      members: [],
      selectedStyle: null,
      selectedDistance: null,
      selectedPoolType: null,
      resultsHeaders: [
        {
          text: "Data",
          value: "date",
          sortable: false,
        },
        { text: "Timp", value: "miliseconds", sortable: false },
        { text: "Vechime", value: "since", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      results: [],
      series: [
        {
          name: "Timp",
          data: [],
        },
      ],
      chartOptions: {
        yaxis: {
          labels: {
            formatter: (value, data) => {
              return this.msToTime(value);
            },
          },
        },
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        tooltip: {
          y: {
            formatter: (value, data) => {
              return this.msToTime(value);
            },
          },
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 1,
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        labels: [],
      },
    };
  },
  computed: {
    ...mapState("authentication", {
      auth_token: "auth_token",
      user: "user"
    }),
  },
  async created() {
    this.isLoading = true;
    await this.loadResults(2, 1, 50);
    this.isLoading = false;
  },
  watch: {
    async selectedDistance(newDist, oldDist) {
      if (this.isLoading || this.isLoadingResults) return;

      this.isLoadingResults = true;
      await this.loadResults(
        this.selectedPoolType,
        this.selectedStyle,
        newDist
      );
      this.isLoadingResults = false;
    },
    async selectedPoolType(newPoolType, oldPoolType) {
      if (this.isLoading || this.isLoadingResults) return;

      this.isLoadingResults = true;
      await this.loadResults(
        newPoolType,
        this.selectedStyle,
        this.selectedDistance
      );
      this.isLoadingResults = false;
    },
    async selectedStyle(newStyle, oldStyle) {
      if (this.isLoading || this.isLoadingResults) return;

      this.isLoadingResults = true;
      await this.loadResults(
        this.selectedPoolType,
        newStyle,
        this.selectedDistance
      );
      this.isLoadingResults = false;
    },
  },
  methods: {
    async loadResults(poolType, style, distance) {
      try {
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/users/${this.user.id}/results?poolType=${poolType}&style=${style}&distance=${distance}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        this.results = response.data;
        this.results.forEach((result) => {
          if (result.splits.length > 0) {
            result.splits = this.mapSplits(
              result.splits.concat(result.miliseconds),
              result.splitDistance
            );
          }
        });
        this.chartOptions = this.getChartOptions(
          this.results
            .map((r) => this.$moment(r.date).format("DD-MM-YYYY HH:mm"))
            .reverse()
        );
        this.series = [
          {
            name: "Timp",
            data: this.results.map((r) => r.miliseconds).reverse(),
          },
        ];
      } catch (_) {
        this.isLoading = false;
        this.isLoadingResults = false;
      }
    },
    msToTime(s, shortFormat = false) {
      function pad(n, z) {
        z = z || 2;
        return ("00" + n).slice(-z);
      }
      var ms = s % 1000;
      s = (s - ms) / 1000;
      var secs = s % 60;
      s = (s - secs) / 60;
      var mins = s % 60;
      var hrs = (s - mins) / 60;
      if (shortFormat) {
        if (mins > 0) {
          return mins + "m " + secs + "s " + pad(ms) + "ms";
        } else if (secs > 0) {
          return secs + "s " + pad(ms) + "ms";
        } else {
          return pad(ms) + "ms";
        }
      }
      return pad(mins) + ":" + pad(secs) + "." + pad(ms);
    },
    changeDistance(index) {
      this.selectedDistance = this.distances[index];
    },
    changeStyle(index) {
      this.selectedStyle = this.styles[index].value;
    },
    changePoolType(index) {
      this.selectedPoolType = this.poolTypes[index].value;
    },
    getChartOptions(labels) {
      return {
        yaxis: {
          labels: {
            formatter: (value, data) => {
              return this.msToTime(value);
            },
          },
        },
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        tooltip: {
          y: {
            formatter: (value, data) => {
              return this.msToTime(value);
            },
          },
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 1,
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        labels: labels,
      };
    },
    mapSplits(splits, splitDistance) {
      let mappedSplits = [];
      let index = 1;
      let previousLap = 0;
      let previousSplit = 0;
      let timeSplit = 0;
      let bestLap = Number.MAX_VALUE;
      let bestLapAtDistance = 0;
      splits.forEach((s) => {
        timeSplit = s - previousLap;
        if (timeSplit < bestLap) {
          bestLap = timeSplit;
          bestLapAtDistance = splitDistance * index;
        }
        let variation =
          previousLap == 0 || previousSplit - timeSplit == 0
            ? " "
            : (previousSplit - timeSplit > 0 ? "- " : "+ ") +
              this.msToTime(Math.abs(previousSplit - timeSplit), true);

        mappedSplits.push({
          distance: splitDistance * index,
          time: this.msToTime(s),
          timeSplit: this.msToTime(timeSplit),
          variation: variation,
          isBestLap: false,
        });
        previousLap = s;
        previousSplit = timeSplit;
        index++;
      });
      let bestSplit = mappedSplits.find((s) => s.distance == bestLapAtDistance);
      bestSplit.isBestLap = true;
      return mappedSplits;
    },
  },
};
</script>